/* eslint-disable no-unused-vars */
// import socketIO from "socket.io-client";

var latestData;
const resValues = {
  // minutes
  // 1: 1,
  // 3: 3,
  // 5: 5,
  // 15: 10,
  // 30: 30,
  // hours
  60: 60,
  120: 120,
  240: 240,
  360: 360,
  720: 720,
  // days
  "1D": 1440,
  "3D": 4320,
  "1W": 10080,
  "1M": 43200
};

function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }

  return {
    symbol: match[1],
    exchange: match[2],
  };
}

export const configurationData = {
  supports_marks: true,
  supported_resolutions: [
    // minutes
    // "1",
    // "3",
    // "5",
    // "15",
    // "30",
    // hours
    "60",
    "120",
    "240",
    "360",
    "720",
    // days
    "D",
    "W",
    "M",
  ],
  supports_search: true,
  supports_group_request: false,
  supports_timescale_marks: false,
  supports_time: true,  
};

const channelToSubscription = new Map();

// Chart Methods
// eslint-disable-next-line import/no-anonymous-default-export
const datafeed = (tokenId) => {
  return {
    onReady: (callback) => {
      setTimeout(() => callback(configurationData), 500);
      
    },
    searchSymbols: async () => {
      // Code here...
    },
    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback
    ) => {
      let symbolInfo = {
        name: symbolName,
        has_intraday: true,
        has_no_volume: false,
        session: "24x7",
        timezone: "Europe/Athens",
        exchange: "",
        minmov: 0.00000000001,
        pricescale: 100000000,
        has_weekly_and_monthly: true,
        has_intraday: true,
        volume_precision: 2,
        data_status: "streaming",
        supported_resolutions: configurationData.supported_resolutions,
      };

      onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback
    ) => {
        const resVal = resValues[resolution];
        const { from, to } = periodParams;
        try {
            // const url = 'https://hemeratrading.net/predict/default.json';
            // const response = await fetch(url, {
            //     method: 'GET',
            //     mode: 'cors',
            //     // headers: {
            //     //     'Content-Type': 'application/json',
            //     //     'Access-Control-Allow-Origin' : '*'
            //     // }
            // })
            // let data = await response.json()
            // setTotalData(data);
            // setCandleData(candle_data)
            // setLineData(line_data)
            // console.log('data', data)
            let bars = [{time: new Date("2024-08-28T14:30:00Z").getTime(), open: 59379, high: 59420, low: 59280, close: 59300},
              {time: new Date("2024-08-28T15:30:00Z").getTime(), open: 59300, high: 59340, low: 59200, close: 59250},
              {time: new Date("2024-08-28T16:30:00Z").getTime(), open: 59250, high: 59300, low: 59150, close: 59200},
              {time: new Date("2024-08-28T17:30:00Z").getTime(), open: 59200, high: 59270, low: 59100, close: 59200},
              {time: new Date("2024-08-28T18:30:00Z").getTime(), open: 59200, high: 59350, low: 59180, close: 59300},
              {time: new Date("2024-08-28T19:30:00Z").getTime(), open: 59300, high: 59380, low: 59220, close: 59250},
              {time: new Date("2024-08-28T20:30:00Z").getTime(), open: 59250, high: 59300, low: 59150, close: 59200},
              {time: new Date("2024-08-28T21:30:00Z").getTime(), open: 59200, high: 59240, low: 59100, close: 59150},
              {time: new Date("2024-08-28T22:30:00Z").getTime(), open: 59150, high: 59210, low: 59100, close: 59180},
              {time: new Date("2024-08-28T23:30:00Z").getTime(), open: 59180, high: 59260, low: 59090, close: 59150},
              {time: new Date("2024-08-29T00:30:00Z").getTime(), open: 59150, high: 59180, low: 59050, close: 59090},
              {time: new Date("2024-08-29T01:30:00Z").getTime(), open: 59090, high: 59190, low: 59050, close: 59100},
              {time: new Date("2024-08-29T02:30:00Z").getTime(), open: 59100, high: 59150, low: 59000, close: 59050},
              {time: new Date("2024-08-29T03:30:00Z").getTime(), open: 59050, high: 59100, low: 58950, close: 59000},
              {time: new Date("2024-08-29T04:30:00Z").getTime(), open: 59000, high: 59080, low: 58900, close: 58950},
              {time: new Date("2024-08-29T05:30:00Z").getTime(), open: 58950, high: 59030, low: 58870, close: 58980},
              {time: new Date("2024-08-29T06:30:00Z").getTime(), open: 58980, high: 59090, low: 58890, close: 58950},
              {time: new Date("2024-08-29T07:30:00Z").getTime(), open: 58950, high: 59000, low: 58820, close: 58900},
              {time: new Date("2024-08-29T08:30:00Z").getTime(), open: 58900, high: 58970, low: 58800, close: 58860},
              {time: new Date("2024-08-29T09:30:00Z").getTime(), open: 58860, high: 58910, low: 58700, close: 58800},
              {time: new Date("2024-08-29T10:30:00Z").getTime(), open: 58800, high: 58870, low: 58720, close: 58750},
              {time: new Date("2024-08-29T11:30:00Z").getTime(), open: 58750, high: 58850, low: 58700, close: 58800},
              {time: new Date("2024-08-29T12:30:00Z").getTime(), open: 58800, high: 58870, low: 58700, close: 58810},
              {time: new Date("2024-08-29T13:30:00Z").getTime(), open: 58810, high: 58880, low: 58720, close: 58840},
              {time: new Date("2024-08-29T14:30:00Z").getTime(), open: 58840, high: 58900, low: 58750, close: 58800},
              {time: new Date("2024-08-29T15:30:00Z").getTime(), open: 58800, high: 58950, low: 58750, close: 58850},
              {time: new Date("2024-08-29T16:30:00Z").getTime(), open: 58850, high: 59000, low: 58780, close: 58950},
              {time: new Date("2024-08-29T17:30:00Z").getTime(), open: 58950, high: 59050, low: 58890, close: 58980},
              {time: new Date("2024-08-29T18:30:00Z").getTime(), open: 58980, high: 59100, low: 58920, close: 59050},
              {time: new Date("2024-08-29T19:30:00Z").getTime(), open: 59050, high: 59120, low: 58970, close: 59010},
              {time: new Date("2024-08-29T20:30:00Z").getTime(), open: 59010, high: 59100, low: 58950, close: 59080},
              {time: new Date("2024-08-29T21:30:00Z").getTime(), open: 59080, high: 59170, low: 59010, close: 59120},
              {time: new Date("2024-08-29T22:30:00Z").getTime(), open: 59120, high: 59230, low: 59070, close: 59190},
              {time: new Date("2024-08-29T23:30:00Z").getTime(), open: 59190, high: 59270, low: 59130, close: 59220},
              {time: new Date("2024-08-30T00:30:00Z").getTime(), open: 59220, high: 59290, low: 59160, close: 59250},
              {time: new Date("2024-08-30T01:30:00Z").getTime(), open: 59250, high: 59340, low: 59190, close: 59280},
              {time: new Date("2024-08-30T02:30:00Z").getTime(), open: 59280, high: 59350, low: 59230, close: 59300},
              {time: new Date("2024-08-30T03:30:00Z").getTime(), open: 59300, high: 59380, low: 59260, close: 59320},
              {time: new Date("2024-08-30T04:30:00Z").getTime(), open: 59320, high: 59400, low: 59290, close: 59350}]            
            let candleData = []
            bars.map(candle => {
              // new Date(candle.timestamp).getTime() < Date.now() ? 
              // candleData.push({ time: new Date(candle.timestamp).getTime() - 86400000 * 8, open: candle.open, high: candle.high, low: candle.low, close: candle.close }) :
              candleData.push({ time: candle.time, open: candle.open, high: candle.high, low: candle.low, close: candle.close })
            })

          if (!bars.length) {
            onHistoryCallback([], { noData: true });
          }
          console.log('CcandleData', candleData)
            candleData = candleData.filter((bar) => bar.time >= from * 1000 && bar.time < to * 1000 );

            candleData = candleData.sort(function (a, b) {
            if (a.time < b.time) return -1;
            else if (a.time > b.time) return 1;
            return 0;
          });

          latestData = candleData[candleData.length - 1];
          window.delta = 0;
          console.log("data", candleData, from, to)

          onHistoryCallback(candleData, { noData: false });
        } catch (error) {
          onErrorCallback(error);
        }
    },

    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastDailyBar
    ) => {
      const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
      const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.symbol}`;
      const handler = {
        id: subscribeUID,
        callback: onRealtimeCallback,
      };
      let subscriptionItem = channelToSubscription.get(channelString);
      if (subscriptionItem) {
        // Already subscribed to the channel, use the existing subscription
        subscriptionItem.handlers.push(handler);
        return;
      }
      subscriptionItem = {
        subscribeUID,
        resolution,
        lastDailyBar: lastDailyBar || latestData,
        handlers: [handler],
      };
      channelToSubscription.set(channelString, subscriptionItem);
      console.log(
        "[subscribeBars]: Subscribe to streaming. Channel:",
        channelString
      );
      // socket.emit('SubAdd', { subs: channelString });
    },

    unsubscribeBars: (subscriberUID) => {
      // Code here...
    },
  };
};

export default datafeed;
