/*global TradingView*/
/*eslint no-undef: "error"*/
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use'
import Datafeed from './datafeed';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const timeFrames = [
  {
    text: "1d",
    resolution: "1",
    description: "1 day",
  },
  {
    text: "5d",
    resolution: "5",
    description: "5 days",
  },
  {
    text: "1m",
    resolution: "120",
  },
  {
    text: "3m",
    resolution: "240",
  },
  {
    text: "1y",
    resolution: "1D",
  },
];


const TIMEZONE = {
  '-10': ['Pacific/Honolulu'],
  '-8': ['America/Anchorage', 'America/Juneau'],
  '-7': ['America/Los_Angeles', 'America/Phoenix', 'America/Vancouver'],
  '-6': ['America/Mexico_City'],
  '-5': ['America/Bogota', 'America/Chicago', 'America/Lima'],
  '-4': ['America/Caracas', 'America/New_York', 'America/Santiago', 'America/Toronto'],
  '-3': ['America/Argentina/Buenos_Aires', 'America/Sao_Paulo'],
  0: ['Atlantic/Reykjavik'],
  1: ['Africa/Casablanca', 'Africa/Lagos', 'Europe/London'],
  2: [
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Budapest',
    'Europe/Copenhagen',
    'Africa/Johannesburg',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Rome',
    'Europe/Stockholm',
    'Europe/Warsaw',
    'Europe/Zurich',
  ],
  3: [
    'Asia/Bahrain',
    'Europe/Athens',
    'Europe/Bucharest',
    'Africa/Cairo',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Asia/Jerusalem',
    'Asia/Kuwait',
    'Europe/Moscow',
    'Asia/Nicosia',
    'Asia/Qatar',
    'Europe/Riga',
  ],
  4: ['Asia/Dubai'],
  5: ['Asia/Karachi'],
  6: ['Asia/Almaty'],
  6.5: ['Asia/Yangon'],
  7: ['Asia/Bangkok'],
  8: ['Asia/Chongqing'],
  9: ['Asia/Tokyo'],
  9.5: ['Australia/Adelaide'],
  10: ['Australia/Brisbane'],
  11: ['Pacific/Norfolk'],
  12.75: ['Pacific/Chatham'],
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Chart = (props) => {
  const { predictId } = useParams()
  const { symbol, tokenId, interval, width, height } = props;
  const [windowDimensions, setWindowDimensions] = useState(null);
  const offset = (-1 * new Date().getTimezoneOffset()) / 60;
  const below600 = useMedia('(max-width: 600px)')
  const below800 = useMedia('(max-width: 800px)')

  const [isMobile, setIsMobile] = useState(false)
  const [isBook, setIsBook] = useState(false)

  useEffect(() => {
    setWindowDimensions(getWindowDimensions())
  }, [])

  useEffect(() => {
    setIsMobile(below600)
  }, [below600])
  useEffect(() => {
    setIsBook(below800)
  }, [below800])

  const chartHeight = isMobile ? 400 : 400
  const chartWidth = isBook ? isMobile ? windowDimensions.width - 60 : windowDimensions.width : width

  useEffect(() => {
    if (symbol && interval) {
      //eslint-ignore-next-line
      const widget = (window.tvWidget = new TradingView.widget({
        symbol: symbol,
        interval: interval,
        fullscreen: false,
        width: "100%",
        height: "100%",
        container: 'tv_chart_container',
        datafeed: Datafeed(tokenId),
        library_path: '/charting_library/',
        toolbar_bg: '#0b1217',
        autosize: true,
        overrides: {    
          'paneProperties.rightMargin': 0,
          'paneProperties.background': '#0b1217',
          'paneProperties.backgroundType': 'solid',
          'paneProperties.backgroundGradientEndColor': '#0b1217',
          'paneProperties.backgroundGradientStartColor': '#0b1217',
          'paneProperties.vertGridProperties.color': '#E3E3E5', // Grid Vertical Lines Color
          'paneProperties.horzGridProperties.color': '#E3E3E5', // Grid Horizontal Lines Color
          'mainSeriesProperties.candleStyle.upColor': '#11CC39', // Up Candle Color
          'mainSeriesProperties.candleStyle.downColor': '#E20E2C', // Down Candle Color
          'mainSeriesProperties.candleStyle.borderUpColor': '#11CC39', // Up Candle Border Color
          'mainSeriesProperties.candleStyle.borderDownColor': '#E20E2C', // Down Candle Border Color
          'mainSeriesProperties.candleStyle.drawBorder': false, // Disable candle borders
          'mainSeriesProperties.minTick': '100000000,1,false',
          "scalesProperties.textSize": 18,
          "scalesProperties.showLeftScale": false,
          "mainSeriesProperties.candleStyle.hollowCandle": true,
        },
        disabled_features: ['header_symbol_search'],
        time_frames: timeFrames,
        theme: 'Dark',
        timezone: TIMEZONE[offset][0],
      }));
      widget.onChartReady(async () => {
        widget.activeChart().setTimezone('UTC')
        widget.chart().setVisibleRange({
          from: new Date().getTime() / 1000 - 48 * 60 * 60,
          to: new Date().getTime() / 1000 + 48 * 60 * 60
        })
        widget.activeChart().setTimezone('UTC');
        const chart = widget.activeChart()
        // const url = `https://hemeratrading.net/predict/default.json`;
        // const response = await fetch(url, {
        //     method: 'GET',
        //     mode: 'cors',
        //     // headers: {
        //     //     'Content-Type': 'application/json',
        //     //     'Access-Control-Allow-Origin' : '*'
        //     // }00000000
        // })
        // let data = await response.json()
        // let lineData = data.filter(line => new Date(line.timestamp).getTime() < Date.now())        
        // console.log('lineData', lineData)

        // const forecastData = [];
        // lineData.map(line => forecastData.push({time: new Date(line.timestamp).getTime(), high: line.high, low: line.low, open: line.open, close: line.close}))
        // console.log('lineData2', forecastData)
      // const lineSeries = chart.createStudy('ISAGEP [Loxx]', false, false, forecastData, null, {
      //   'plot.color': 'blue',  // Line color
      //   'plot.linewidth': 5,  // Line thickness
      //   'plot.linestyle': 0   // Solid line
      // });

      });
    }
  }, [symbol, interval]);
  return (
    <>
      <div id="tv_chart_container" style={{ height: chartHeight, backgroundColor: 'black' }} />
    </>
  );
};

export default Chart;
